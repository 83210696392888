import React from 'react'
import headerImg from '../images/headerimg.jpg';
const Header = () => {
    return (
        <div className='container header-image'>
            <div className="hero-image">
            </div>
        </div>
    )
}

export default Header
