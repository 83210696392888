import React from 'react'

const Footer = () => {
    return (
        <div className=' w-100 text-center  footer'>
         <p>© 2022 DanishTeachnical. All right reserved </p>   
        </div>
    )
}

export default Footer
