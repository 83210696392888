import React from 'react'
import Navbar from '../component/Navbar'
import Services from '../component/Services'
import Footer from '../component/Footer'

const Service = () => {
    return (
        <div>
            <Navbar />
            <Services />
            <div style={{marginTop:'220px'}}>
            <Footer />
            </div>
            
        </div>
    )
}

export default Service
