import React from 'react'
import Navbar from '../component/Navbar'
import Pricing from '../component/Pricing'
import Footer from '../component/Footer'

const Price = () => {
    return (
        <div>
            <Navbar />
            <Pricing />
            <div style={{marginTop:'170px'}}>
            <Footer />
            </div>

        </div>
    )
}

export default Price
