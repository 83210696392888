const priceApi = [
    {
        name : 'Basic Plan',
        amount : 19,
        para1 : 'Upto 50 keyphrases optimized',
        para2 : 'Upto 50 keyphrases optimized',
        para3 : 'Upto 50 keyphrases optimized',
    },
    {
        name : 'Popular Plan',
        amount : 23,
        para1 : 'Upto 50 keyphrases optimized',
        para2 : 'Upto 50 keyphrases optimized',
        para3 : 'Upto 50 keyphrases optimized',
    }, 
     {
        name : 'Premium Plan',
        amount : 40,
        para1 : 'Upto 50 keyphrases optimized',
        para2 : 'Upto 50 keyphrases optimized',
        para3 : 'Upto 50 keyphrases optimized',
    }
]
export default  priceApi;